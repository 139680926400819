<template>
  <v-container fluid>
    <modal-eliminar :objModalEliminar="objModalEliminar"></modal-eliminar>
    <modal-detalle-producto
      :objModalDetalleProducto="objModalDetalleProducto"
    ></modal-detalle-producto>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center">
            GESTIONAR PEDIDOS PERSONALIZADOS
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <div class="col-12">
                <p>
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'inicioPanel' }"
                    >Inicio</router-link
                  >
                  /
                  <router-link
                    exact=""
                    style="text-decoration: none"
                    :to="{ name: 'pedidoPersonalizadoPanel' }"
                    >Pedidos Personalizados</router-link
                  >
                </p>
              </div>
              <v-col cols="12" class="text-right">
                <v-btn
                  v-show="validarPermiso('pedidosPersonalizados.crear')"
                  :to="{ name: 'pedidoPersonalizadoCrearPanel' }"
                  color="primary"
                  dark
                  large=""
                >
                  <v-icon left="" large="">mdi-plus-circle</v-icon> NUEVO PEDIDO
                  PERSONALIZADO
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  small
                  title="Limpiar filtros"
                  fab
                  dark
                  color="blue-grey"
                  depressed
                  @click="limpiarFiltros()"
                >
                  <v-icon dark>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col xl="2" lg="2" md="2" sm="12" cols="12">
                <v-select
                  v-model="cmbCantidadRegistros"
                  item-text="texto"
                  item-value="valor"
                  :items="arrCantidadRegistros"
                  label="Cantidad de registros"
                  @change="listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <!--<v-col xl="10" lg="10" md="2" sm="12" cols="12">
                            <v-text-field dense label="Buscar registro" prepend-icon="mdi-magnify" clearable type="text" v-model="iptBuscarRegisro" @keyup.enter="listarRegistros(cmbCantidadRegistros, null, iptBuscarRegisro)" @click:clear="listarRegistros(cmbCantidadRegistros, null, null)" @keyup.delete="iptBuscarRegisro === '' ? listarRegistros(cmbCantidadRegistros, null, null) : ''"></v-text-field>
                        </v-col>-->
              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-simple-table dense="" fixed-header="" ref="tblListado">
                  <template v-slot:default>
                    <thead class="text-no-wrap">
                      <tr>
                        <th>ACCIONES</th>
                        <th>FEC. REGISTRO</th>
                        <th>HORA REG.</th>
                        <th>FEC. ENTREGA</th>
                        <th>HORA ENTREGA</th>
                        <th>DETALLE</th>
                        <th>URL. GENEREADO</th>
                        <th>PRECIO DE ENVIO</th>
                        <th>DESCUENTO</th>
                        <th>MONTO TOTAL</th>
                        <th>ESTADO DE ENVIO</th>
                        <th>ESTADO DE PAGO</th>
                      </tr>
                    </thead>
                    <tbody class="text-no-wrap">
                      <tr
                        v-for="item in arrRegistrosTabla"
                        :key="item.idpedido_personalizado"
                      >
                        <td>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                dark=""
                                depressed=""
                                color="cyan"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon left="">mdi-cursor-default-click</v-icon>
                                Acciones
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-show="validarPermiso('pedidosPersonalizados.eliminar')"
                                @click="
                                  objModalEliminar = {
                                    idpedido_personalizado: item.idpedido_personalizado,
                                    openModal: true,
                                  }
                                "
                              >
                                <v-list-item-title>
                                  <v-icon>mdi-trash-can</v-icon> Eliminar
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                        <td v-text="$moment(item.fecha_pedido).format('DD/MM/YYYY')"></td>
                        <td v-text="$moment(item.fecha_pedido).format('hh:mm A')"></td>
                        <td>
                          <p
                            v-if="item.fecha_entrega != null && item.fecha_entrega != ''"
                            v-text="$moment(item.fecha_entrega).format('DD/MM/YYYY')"
                          ></p>
                        </td>
                        <td>
                          <p
                            v-if="item.fecha_entrega != null && item.fecha_entrega != ''"
                            v-text="$moment(item.fecha_entrega).format('hh:mm A')"
                          ></p>
                        </td>
                        <td>
                          <v-btn
                            @click="
                              objModalDetalleProducto = {
                                detalleProducto: item.detalles,
                                openModal: true,
                              }
                            "
                            elevation=""
                            color="info"
                            icon
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </td>
                        <td v-text="rutaCodigo(item.codigo_ruta)"></td>
                        <td v-text="'S/. ' + item.envio"></td>
                        <td v-text="'S/. ' + item.descuento"></td>
                        <td v-text="'S/. ' + item.total"></td>
                        <td v-text="item.estado_envio"></td>
                        <td>
                          <p
                            :class="
                              item.estado_pedido === 'en espera'
                                ? 'warning--text'
                                : 'success--text'
                            "
                            v-text="item.estado_pedido"
                          ></p>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p>
                  Mostrando del registro <span v-text="primerRegistro"></span> al
                  <span v-text="ultimoRegistro"></span> de un total de
                  <span v-text="totalRegistro"></span> filas
                </p>
              </v-col>

              <v-col cols="12 text-center" v-show="loaderListado">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="100"
                  :width="7"
                ></v-progress-circular>
              </v-col>

              <v-col cols="12" v-show="arrRegistrosTabla.length > 0">
                <v-pagination
                  circle
                  v-model="paginaActual"
                  :length="cantidadpedidoPersonalizado"
                  :total-visible="7"
                  @input="
                    listarRegistros(cmbCantidadRegistros, paginaActual, iptBuscarRegisro)
                  "
                  :disabled="loaderListado"
                ></v-pagination>
              </v-col>

              <v-col cols="12" v-show="alertRegistroNoEcontrado">
                <v-alert type="error" dense outlined class="text-center">
                  NO HAY REGISTROS DISPONIBLES PARA MOSTRAR
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import modalEliminar from "./Eliminar";
import modalDetalleProducto from "./DetalleProducto";
export default {
  components: {
    modalEliminar,
    modalDetalleProducto,
  },
  data() {
    return {
      arrCantidadRegistros: [
        {
          texto: 10,
          valor: 10,
        },
        {
          texto: 30,
          valor: 30,
        },
        {
          texto: 50,
          valor: 50,
        },
        {
          texto: 100,
          valor: 100,
        },
        {
          texto: 500,
          valor: 500,
        },
      ],
      loaderListado: false,
      alertRegistroNoEcontrado: false,
      cmbCantidadRegistros: 10,
      arrRegistrosTabla: [],
      paginaActual: 1,
      cantidadpedidoPersonalizado: 1,
      primerRegistro: 0,
      ultimoRegistro: 0,
      totalRegistro: 0,
      iptBuscarRegisro: "",
      objModalEliminar: null,
      objModalDetalleProducto: null,
    };
  },

  methods: {
    rutaCodigo(codigo) {
      return process.env.VUE_APP_URL_WEB + "ver-carrito/" + codigo;
    },

    listarRegistros(
      cantidadRegistros = null,
      paginaActual = null,
      iptBuscarRegisro = null
    ) {
      this.arrRegistrosTabla = [];
      this.loaderListado = true;
      this.alertRegistroNoEcontrado = false;
      this.axios({
        method: "GET",
        url: "api/panel/pedidos-personalizados",
        params: {
          cantidadRegistros: cantidadRegistros,
          paginaActual: paginaActual,
          iptBuscarRegisro: iptBuscarRegisro,
        },
      })
        .then((response) => {
          let listado = response.data.data;

          this.arrRegistrosTabla = listado.data;
          this.paginaActual = listado.current_page;
          this.cantidadpedidoPersonalizado = listado.last_page;
          this.primerRegistro = listado.from;
          this.ultimoRegistro = listado.to;
          this.totalRegistro = listado.total;

          if (this.arrRegistrosTabla.length === 0) {
            this.alertRegistroNoEcontrado = true;
          } else {
            this.alertRegistroNoEcontrado = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        })
        .finally(() => {
          this.loaderListado = false;
          this.$vuetify.goTo(this.$refs.tblListado);
        });
    },

    limpiarFiltros() {
      this.cmbCantidadRegistros = 10;
      this.iptBuscarRegisro = "";
      this.listarRegistros();
    },
  },

  created() {
    if (!this.validarPermiso("pedidosPersonalizados.listado")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    this.listarRegistros();
  },
};
</script>
