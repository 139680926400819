<template>
  <div>
    <v-dialog v-model="openModal" persistent max-width="800">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-center font-weight-bold">DETALLE DE PEDIDO PERSONALIZADO</p>
            </v-col>

            <v-col cols="12">
              <v-simple-table class="tabla-productos">
                <thead>
                  <tr>
                    <th>N°</th>
                    <th>IMAGEN</th>
                    <th>CÓDIGO</th>
                    <th>NOMBRE MONTURA</th>
                    <th>TIPO MONTURA</th>
                    <th>PRECIO MONTURA</th>
                    <th>NOMBRE LUNA</th>
                    <th>PRECIO LUNA</th>
                    <th>IMPORTE TOTAL</th>
                    <th>TIPO MEDIDA</th>
                    <th>RECETA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, index) in productoDetalle" :key="index">
                    <td>
                      <p v-text="index + 1"></p>
                    </td>
                    <td class="text-center">
                      <img
                        v-if="p.producto.imagenes.length > 0"
                        :src="assetAWS('monturas/' + p.producto.imagenes[0].nombre)"
                        width="30px"
                        height="25px"
                      />
                    </td>
                    <td>
                      <p v-text="p.producto.codigo"></p>
                    </td>
                    <td>
                      <p v-text="p.producto.nombre"></p>
                    </td>
                    <td>
                      <p v-if="p.tipo_montura != null" v-text="p.tipo_montura.nombre"></p>
                    </td>
                    <td>
                      <p v-text="p.precio_producto"></p>
                    </td>
                    <td>
                      <p v-text="p.nombre_luna"></p>
                    </td>
                    <td>
                      <p v-text="p.precio_luna"></p>
                    </td>
                    <td>
                      <p v-text="p.total"></p>
                    </td>
                    <td>
                      <p v-if="p.tipo_medida != null" v-text="p.tipo_medida.nombre"></p>
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="datosReceta(p)"
                        v-if="p.idmedida != null"
                        color="primary"
                        small
                      >
                        Ver <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="12" v-show="receta.codigoMontura != ''">
              <v-simple-table class="tabla-receta">
                <thead>
                  <tr>
                    <th>CODIGO DE MONTURA</th>
                    <th>NOMBRE MONTURA</th>
                    <th>NOMBRE MEDIDA</th>
                    <th class="px-15">OD ESFERA</th>
                    <th class="px-15">OD CILINDRO</th>
                    <th class="px-15">OD EJE</th>
                    <th class="px-15">OI ESFERA</th>
                    <th class="px-15">OI CILINDRO</th>
                    <th class="px-15">OI EJE</th>
                    <th class="px-15">DIP</th>
                    <th class="px-15">FECHA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p v-text="receta.codigoMontura"></p>
                    </td>
                    <td>
                      <p v-text="receta.nombreMontura"></p>
                    </td>
                    <td>
                      <v-text-field
                        class="mt-6"
                        dense
                        label="Nombre"
                        v-model="receta.nombreMedida"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(-16, 16, 0.25, '', 2)"
                        label="ESFERA"
                        v-model="receta.odEsfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(-6, 0, 0.25, '', 2)"
                        label="CILINDRO"
                        v-model="receta.odCilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(0, 180, 1, '°', 0)"
                        label="EJE"
                        v-model="receta.odEje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(-16, 16, 0.25, '', 2)"
                        label="ESFERA"
                        v-model="receta.oiEsfera"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(-6, 0, 0.25, '', 2)"
                        label="CILINDRO"
                        v-model="receta.oiCilindro"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(0, 180, 1, '°', 0)"
                        label="EJE"
                        v-model="receta.oiEje"
                      ></v-select>
                    </td>
                    <td>
                      <v-select
                        class="mt-6"
                        item-text="texto"
                        item-value="valor"
                        dense
                        :items="valoresRango(50, 80, 1, ' MM', 0)"
                        label="DIP"
                        v-model="receta.dip"
                      ></v-select>
                    </td>
                    <td>
                      <v-menu :close-on-content-click="false" max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mt-6"
                            :value="formatFecha"
                            dense
                            clearable
                            label="Fecha"
                            readonly
                            @click:clear="receta.fechaMedida = ''"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receta.fechaMedida"
                          locale="es-es"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-btn
                :loading="processForm"
                class="float-right mt-2"
                color="success"
                @click="modificarReceta"
              >
                <v-icon left="">mdi-check-bold</v-icon> Modificar
              </v-btn>
            </v-col>

            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                :loading="processForm"
                class="mr-2"
                color="error"
                outlined=""
                @click="openModal = false"
              >
                <v-icon left="">mdi-backspace</v-icon> Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openModal: false,
      idpedido_personalizado_detalle: "",
      idmedida: "",
      receta: {
        codigoMontura: "",
        nombreMontura: "",
        nombreMedida: "",
        odEsfera: 0,
        odCilindro: 0,
        odEje: 0,
        oiEsfera: 0,
        oiCilindro: 0,
        oiEje: 0,
        dip: 0,
        fechaMedida: "",
      },
      productoDetalle: [],
      processForm: false,
    };
  },
  props: {
    objModalDetalleProducto: {
      type: Object,
    },
  },
  watch: {
    objModalDetalleProducto(val) {
      this.openModal = val.openModal;
      this.productoDetalle = val.detalleProducto;

      this.idpedido_personalizado_detalle = "";
      this.idmedida = "";
      this.receta.codigoMontura = "";
      this.receta.nombreMontura = "";
      this.receta.nombreMedida = "";
      this.receta.odEsfera = 0;
      this.receta.odCilindro = 0;
      this.receta.odEje = 0;
      this.receta.oiEsfera = 0;
      this.receta.oiCilindro = 0;
      this.receta.oiEje = 0;
      this.receta.dip = 0;
      this.receta.fechaMedida = "";
    },
  },

  computed: {
    formatFecha() {
      return this.receta.fechaMedida
        ? this.$moment(this.receta.fechaMedida).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    datosReceta(fila) {
      this.idpedido_personalizado_detalle = fila.idpedido_personalizado_detalle;
      this.idmedida = fila.idmedida;
      this.receta.codigoMontura = fila.producto.codigo;
      this.receta.nombreMontura = fila.producto.nombre;
      this.receta.nombreMedida = fila.medida_nombre;
      this.receta.odEsfera = fila.medida_od_esfera;
      this.receta.odCilindro = fila.medida_od_cilindro;
      this.receta.odEje = fila.medida_od_eje;
      this.receta.oiEsfera = fila.medida_oi_esfera;
      this.receta.oiCilindro = fila.medida_oi_cilindro;
      this.receta.oiEje = fila.medida_oi_eje;
      this.receta.dip = fila.medida_dip;
      this.receta.fechaMedida = fila.medida_fecha;
    },
    valoresRango(inicio, final, contador, texto = "", decimal) {
      let valores = [];
      for (inicio; inicio <= final; inicio += contador) {
        valores.push({
          texto: inicio.toFixed(decimal) + texto,
          valor: inicio.toFixed(decimal),
        });
      }
      return valores;
    },

    modificarReceta() {
      this.processForm = true;
      this.axios({
        method: "POST",
        url: "api/panel/pedidos-personalizados/modificarReceta",
        data: {
          idpedido_personalizado_detalle: this.idpedido_personalizado_detalle,
          idmedida: this.idmedida,
          receta: JSON.stringify(this.receta),
        },
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$parent.listarRegistros(
              this.$parent.cmbCantidadRegistros,
              this.$parent.paginaActual,
              this.$parent.iptBuscarRegisro
            );
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error al modificar este registro", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.processForm = false;
          this.openModal = false;
        });
    },
  },
};
</script>

<style scoped>
.tabla-productos {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-productos thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-productos tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta {
  width: 100% !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.tabla-receta thead tr th {
  white-space: nowrap !important;
  font-size: 12px;
  border: 1px solid silver;
  padding-left: 5px;
  padding-right: 5px;
}

.tabla-receta tbody tr td {
  white-space: nowrap !important;
  border: 1px solid silver;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
